import { ReactNode, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useThemeMode } from "../../../_metronic/partials";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useLayout } from "../../../_metronic/layout/core";

const BODY_CLASSES = ["bgi-size-cover", "bgi-position-center", "bgi-no-repeat"];

interface ErrorsLayoutProps {
  children?: ReactNode;
}

const ErrorsLayout = ({ children }: ErrorsLayoutProps) => {
  const { config, setLayout } = useLayout();
  const { mode } = useThemeMode();
  // useEffect(() => {
  //   BODY_CLASSES.forEach((c) => {
  //     document.body.classList.add(c);
  //   });
  //   document.body.style.backgroundImage =
  //     mode === "dark"
  //       ? `url(${toAbsoluteUrl("media/auth/bg7-dark.jpg")})`
  //       : `url(${toAbsoluteUrl("media/auth/bg7.jpg")})`;

  //   return () => {
  //     BODY_CLASSES.forEach((c) => document.body.classList.remove(c));
  //     document.body.style.backgroundImage = "none";
  //   };
  // }, [mode]);

  useEffect(() => {
    let errorPage = document.getElementById("kt_app_page");
    if (errorPage) {
      errorPage.style.backgroundImage =
        mode == "dark"
          ? `url(${toAbsoluteUrl("media/auth/bg7-dark.jpg")})`
          : `url(${toAbsoluteUrl("media/auth/bg7.jpg")})`;
    }
    

    document.getElementById("kt_app_wrapper")?.classList.add("ms-0");
    document.getElementById("kt_app_header_wrapper")?.classList.add("w-100");
    return () => {
      document.getElementById("kt_app_wrapper")?.classList.remove("ms-0");
      if (errorPage) errorPage.style.backgroundImage = "none";
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="d-flex flex-column flex-center text-center p-10">
          <div className="card card-flush  w-lg-650px py-5">
            <div className="card-body py-15 py-lg-20">
            {children || <Outlet />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ErrorsLayout };
