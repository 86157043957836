import { lazy, FC, Suspense, useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useMatch,
} from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { KTSVG, WithChildren, toAbsoluteUrl } from "../../_metronic/helpers";
import { systemMode, useThemeMode } from "../../_metronic/partials/index.ts";
import { useAuth } from "../modules/auth/index.ts";
import React from "react";
import { useLayout } from "../../_metronic/layout/core/_LayoutProvider.tsx";
import { Error404 } from "../modules/errors/components/Error404.tsx";
import { ErrorsLayout } from "../modules/errors/ErrorsLayout.tsx";

const AuthRedirect = import.meta.env.VITE_APP_AUTH_REDIRECT;

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const { config, setLayout } = useLayout();

  const TranscriptPage = lazy(
    () => import("../pages/transcript/TranscriptPage.tsx")
  );

  const CompanyEditor = lazy(() => import("../pages/company/CompanyEditor.tsx"));
  
  const match = useMatch("/dashboard/:company/*");
  // const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  // const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  // const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  // const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  // const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  // const UsersPage = lazy(
  //   () => import("../modules/apps/user-management/UsersPage")
  // );

  var location = useLocation();

  useEffect(() => {
    if (match !== null) {
      if (currentUser) {
        var newConfig = { ...config };
        if (newConfig.app?.sidebar) {
          newConfig.app.sidebar.display = true;
          newConfig.app.sidebar.default!.push!.header = true;
          newConfig.app.sidebar.default!.fixed = { desktop: true };
        }
        if (newConfig.app?.header) {
          newConfig.isEventPage = false;
          newConfig.app.header.default!.container = "fluid";
          newConfig.app.header.default!.fixed!.desktop = true;
          newConfig.app.header.default!.fixed!.mobile = false;
        }
        if (newConfig.app?.footer) {
          newConfig.app.footer.display = false;
        }
        setLayout(newConfig);
      }
    }
  }, [location]);

  useEffect(() => {
    // If the user is not logged in, redirect them to a different URL
    if (!currentUser) {
      window.location.href = AuthRedirect + location.pathname;
    }
  }, [currentUser]);

  return (
    <>
      {currentUser ? (
        <Routes>
          <Route
            path="/dashboard/:company/transcript/edit/:transcriptId"
            element={
              <ErrorBoundary>
                <SuspensedView additionalFallback={<TranscriptFallback />}>
                  <TranscriptPage />
                </SuspensedView>
              </ErrorBoundary>
            }
          />
          <Route
            path="/dashboard/:company/profile/edit/"
            element={
              <ErrorBoundary>
                <SuspensedView additionalFallback={<CompanyFallback />}>
                  <CompanyEditor />
                </SuspensedView>
              </ErrorBoundary>
            }
          />
          {/* <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
          {/* Page Not Found */}
          <Route
            path="*"
            element={
              <ErrorsLayout>
                <Error404></Error404>
              </ErrorsLayout>
            }
          />
        </Routes>
      ) : (
        <></>
      )}
    </>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className="d-flex flex-column justify-content-end"
          style={{ height: "75vh" }}
        >
          <div className="w-100 d-flex justify-content-center">
            <div className="alert bg-light-danger d-flex flex-column flex-sm-row p-5 me-10 ms-10 mb-10">
              <KTSVG
                path="media/icons/duotune/files/fil007.svg"
                className="svg-icon-muted svg-icon-2hx me-4 text-danger"
              />
              <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                <h5 className="mb-1">Something went wrong!</h5>
                <span>
                  We are sorry, there has been an error when retrieving data.
                  Please try again later.
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

interface SuspensedViewProps extends WithChildren {
  additionalFallback?: React.ReactNode;
}

export const SuspensedView: FC<SuspensedViewProps> = ({
  children,
  additionalFallback,
}) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return (
    <Suspense
      fallback={
        <>
          <TopBarProgress />
          {additionalFallback}
        </>
      }
    >
      {children}
    </Suspense>
  );
};
interface SuspensedViewWithErrorProps extends WithChildren {
  additionalFallback?: React.ReactNode;
  errorMessage?: string;
}

export const SuspensedViewWithError: FC<SuspensedViewWithErrorProps> = ({
  children,
  additionalFallback,
  errorMessage,
}) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [children]);

  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });

  if (hasError) {
    return <div>{errorMessage}</div>;
  }

  return (
    <Suspense
      fallback={
        <React.Fragment>
          <TopBarProgress />
          {additionalFallback}
        </React.Fragment>
      }
    >
      {children}
    </Suspense>
  );
};

const TranscriptFallback: React.FC = () => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  return (
    <div className="d-flex justify-content-center" style={{ height: "75vh" }}>
      <div className="d-flex flex-column justify-content-center">
        {calculatedMode === "dark" ? (
          <img
            className="w-75 align-self-center"
            src={toAbsoluteUrl("media/logos/stokkio/logo-white-bubble.svg")}
          />
        ) : (
          <img
            src={toAbsoluteUrl("media/logos/stokkio/logo-black-bubble.svg")}
          />
        )}
        <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
          Loading the trascript ...
        </p>
      </div>
    </div>
  );
};

const CompanyFallback: React.FC = () => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  return (
    <div className="d-flex h-100 w-100">
      <div className="company-page overflow-hidden">
        <div className="card mb-5 row mb-xxl-8 mt-5">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-6">
              <div className="loading-skeleton me-5"></div>
              <div className="flex-grow-1 d-flex align-items-center">
                <div className="d-flex justify-content-between w-100 align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3 h-20px loading-skeleton w-100px"></div>
                    <div className="d-flex align-items-center mb-1">
                      <div className=" loading-skeleton h-40px"></div>
                    </div>
                    <div className="d-flex mt-6">
                      <div className="loading-skeleton h-15px w-90px me-3"></div>
                      <div className="loading-skeleton h-15px w-90px"></div>
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-4">
                    <div className="loading-skeleton h-35px w-90px mb-5"></div>
                    <div className="loading-skeleton h-35px w-90px"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card row shadow-sm mb-5">
          <div className="card-header d-flex align-items-center">
            <div className="loading-skeleton h-30px w-90px"></div>
          </div>
          <div className="card-body">
            <div className="loading-skeleton h-15px w-200px mb-3"></div>
            <div className="loading-skeleton h-15px w-400px mb-3"></div>
            <div className="loading-skeleton h-15px w-400px mb-3"></div>
            <div className="loading-skeleton h-15px w-400px mb-3"></div>
          </div>
        </div>
        <div className="row mb-5 gy-5 justify-content-between">
          <div className="col-sm-6 col-xl-3">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <div className="loading-skeleton h-40px w-40px rounded-circle"></div>
                </div>
                <div className="d-flex flex-column my-7">
                  <div className="loading-skeleton h-40px w-80px mb-3"></div>
                  <div className="loading-skeleton h-15px w-60px"></div>
                </div>
                <div className="loading-skeleton h-20px w-50px"></div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <div className="loading-skeleton h-40px w-40px rounded-circle"></div>
                </div>
                <div className="d-flex flex-column my-7">
                  <div className="loading-skeleton h-40px w-80px mb-3"></div>
                  <div className="loading-skeleton h-15px w-60px"></div>
                </div>
                <div className="loading-skeleton h-20px w-50px"></div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <div className="loading-skeleton h-40px w-40px rounded-circle"></div>
                </div>
                <div className="d-flex flex-column my-7">
                  <div className="loading-skeleton h-40px w-80px mb-3"></div>
                  <div className="loading-skeleton h-15px w-60px"></div>
                </div>
                <div className="loading-skeleton h-20px w-50px"></div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-xl-3">
            <div className="card h-lg-100">
              <div className="card-body d-flex justify-content-between align-items-start flex-column">
                <div className="m-0">
                  <div className="loading-skeleton h-40px w-40px rounded-circle"></div>
                </div>
                <div className="d-flex flex-column my-7">
                  <div className="loading-skeleton h-40px w-80px mb-3"></div>
                  <div className="loading-skeleton h-15px w-60px"></div>
                </div>
                <div className="loading-skeleton h-20px w-50px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-25 end-0 card settings">
        <div className="card-header">
          <div className="card-title">Modules</div>
        </div>
        <div className="card-body h-100">
          <div className="card w-100 mb-5">
            <div className="card-header d-flex align-items-center">
              <div className="loading-skeleton w-80px h-15px"></div>
            </div>
          </div>
          <div className="card w-100 mb-5">
            <div className="card-header d-flex align-items-center">
              <div className="loading-skeleton w-60px h-15px"></div>
            </div>
          </div>
          <div className="card w-100 mb-5">
            <div className="card-header d-flex align-items-center">
              <div className="loading-skeleton w-100px h-15px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EventFallback: React.FC = () => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  return (
    <div className="d-flex justify-content-center" style={{ height: "75vh" }}>
      <div className="d-flex flex-column justify-content-center">
        {calculatedMode === "dark" ? (
          <img
            className="w-75 align-self-center"
            src={toAbsoluteUrl("media/logos/stokkio/logo-white-bubble.svg")}
          />
        ) : (
          <img
            src={toAbsoluteUrl("media/logos/stokkio/logo-black-bubble.svg")}
          />
        )}
        <p className="font-weight-bold mt-4 align-self-center text-muted fs-5">
          Loading the event ...
        </p>
      </div>
    </div>
  );
};

export { PrivateRoutes };
