import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useMatch, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const SidebarMenuMain = () => {
  const intl = useIntl();

  //TODO impleent logic for both company and inverstor sidebar
  const [companyName, setCompanyName] = useState("");

  const match = useMatch("/dashboard/:company/*");

  useEffect(() => {
    if (match?.params.company) {
      setCompanyName(match?.params.company);
    }
  }, [match]);

  return (
    <>
      <SidebarMenuItem
        to={`/dashboard/${companyName}`}
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItem
        to={`/dashboard/${companyName}/events/`}
        icon="calendar-tick"
        title={intl.formatMessage({ id: "MENU.EVENTS" })}
        fontIcon="bi-calendar3"
      />
      <SidebarMenuItem
        to={`/dashboard/${companyName}/questions/`}
        icon="question-2"
        title={intl.formatMessage({ id: "MENU.QUESTIONS" })}
        fontIcon="bi-question-circle"
      />
      <SidebarMenuItem
        to={`/dashboard/${companyName}/posts/`}
        icon="clipboard"
        title={intl.formatMessage({ id: "MENU.POSTS" })}
        fontIcon="bi-stickies"
      />
      <SidebarMenuItem
        to={`/dashboard/${companyName}/analyses/list/`}
        icon="chart-pie-too"
        title={intl.formatMessage({ id: "MENU.ANALYSES" })}
        fontIcon="bi-pie-chart"
      />
      <div className="separator border-gray-500 my-5"></div>
      <SidebarMenuItem
        to={`/dashboard/${companyName}/edit-company/`}
        icon="bank"
        title={intl.formatMessage({ id: "MENU.EDIT.PROFILE" })}
        fontIcon="bi-bank2"
      />
    </>
  );
};

export { SidebarMenuMain };
