import { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { checkIsActive, KTIcon, WithChildren } from "../../../../helpers";
import { useLayout } from "../../../core";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const [isImplemented, setIsImplemented] = useState<boolean>(false);

  const tempRerouteLogic = () => {
    if (to == "/dashboard") {
      setIsImplemented(true);
    }
  };

  useEffect(() => {
    tempRerouteLogic();
  }, []);

  return (
    <div className="menu-item">
      {isImplemented ? (
        <Link
          className={clsx("menu-link without-sub", { active: isActive })}
          to={to}
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
            <span className="menu-icon">
              {" "}
              <KTIcon iconName={icon} iconType="outline" className="fs-2" />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span className="menu-title">{title}</span>
        </Link>
      ) : (
        <a
          className={clsx("menu-link without-sub", { active: isActive })}
          href={"https://stokk.io" + to} target='_blank'
        >
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
            <span className="menu-icon">
              {" "}
              <KTIcon iconName={icon} iconType="outline" className="fs-2" />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === "font" && (
            <i className={clsx("bi fs-3", fontIcon)}></i>
          )}
          <span className="menu-title">{title}</span>
        </a>
      )}
      {children}
    </div>
  );
};

export { SidebarMenuItem };
