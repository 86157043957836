import { useIntl } from "react-intl";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";
import { useEffect, useState } from "react";
import { useLayout } from "../../../core";

export function MenuInner() {
  const intl = useIntl();
  const { config } = useLayout();
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
  const [logoText, setLogoText] = useState<string | undefined>(undefined);
  const [dimensions, setDimensions] = useState({height: '50px', width: '250px'});

  useEffect(() => {
    if(logoUrl == undefined)return;
    const img = new Image();
    img.src = logoUrl;
    img.onload = () => {
      let height = img.naturalHeight;
      let width = img.naturalWidth;
      if(height > 50){
        height = 50;
      }
      if(width > 250){
        width = 250;
      }
      setDimensions({height: `${height}px`, width: `${width}px`});
    };
  }, []);

  useEffect(() => {
    if (config.app?.header?.logoText) {
      setLogoText(config.app?.header?.logoText);
    }
    if (config.app?.header?.logoUrl) {
      setLogoUrl(config.app?.header?.logoUrl);
    }
  }, [config]);

  if (config.isEventPage) {
    return (
      <div className="d-flex h-100 align-items-center">
        {logoUrl ? (<img src={logoUrl} style={{maxHeight: dimensions.height, maxWidth: dimensions.width}} alt="Logo"></img>) : logoText ? ((<h1
          id="header-company-title"
          className="align-self-center display-6 text-white"
        >
          {logoText}
        </h1>)) : (<></>)}
        <p
          id="header-event-title"
          className="ms-10 fs-5 align-self-center mt-5 text-white"
        ></p>
      </div>
    );
  } else if (config.isPublic) {
    return (
      <div className="d-flex h-100">
        <h1
          id="stokkio-logo"
          className="align-self-center display-6 text-white"
        >
          Stokk.io
        </h1>
      </div>
    );
  } else {
    return <></>;
  }
}
