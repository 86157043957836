/* eslint-disable react-refresh/only-export-components */
import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import {
  LayoutSplashScreen,
  useLayout,
} from "../../../../_metronic/layout/core";
import { AuthModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getSession, getUser } from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import {
  Color,
  NotificationsContainerHandle,
} from "../../../../_metronic/partials/notification/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { clearOldDrafts } from "../../../pages/transcript/core/_draftHelpers";

type AuthContextProps = {
  auth: AuthModel | undefined;
  currentUser: UserModel | undefined;
  localUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  currentUser: undefined,
  setCurrentUser: () => {},
  localUser: undefined,
};

const AuthRedirect = import.meta.env.VITE_APP_AUTH_REDIRECT;

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [localUser, setLocalUser] = useState<UserModel | undefined>(
    authHelper.getUser()
  );
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();

  //   const saveAuth = (auth: AuthModel | undefined) => {
  //   setAuth(auth)
  //   if (auth) {
  //     authHelper.setAuth(auth) // Save the access token
  //   } else {
  //     authHelper.removeAuth()
  //   }
  // }

  // const saveUser = (user: UserModel | undefined) => {
  //   setLocalUser(user)
  //   if (user) {
  //     authHelper.setUser(user) // Save user
  //   } else {
  //     authHelper.removeUser()
  //   }
  // }

  // const logout = () => {
  //   // saveAuth(undefined)
  //   // saveUser(undefined)
  //   setCurrentUser(undefined)
  // }

  return (
    <AuthContext.Provider
      value={{ auth, currentUser, localUser, setCurrentUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, currentUser, localUser, setCurrentUser } = useAuth();
  const { config, setLayout } = useLayout();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const notificationsRef = useRef<NotificationsContainerHandle | null>(null);
  const { BASE_URL } = import.meta.env;
  const REDIRECT_URL = import.meta.env.VITE_APP_TEMPORARY_ROOT_REDIRECT;

  const createNewNotification = (color: Color, message: string) => {
    if (!notificationsRef.current) return;
    notificationsRef.current?.createNotification(color, message);
  };

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        var { data } = await getSession();
        if (data.isAuthenticated) {
          var user = await getUser();
          setCurrentUser(user.data);
          if (window.location.pathname === BASE_URL) {
            window.location.href = REDIRECT_URL;
            return;
          }
          clearOldDrafts();
          setShowSplashScreen(false);
        }
        if (window.location.pathname === BASE_URL) {
          window.location.href = REDIRECT_URL;
          return;
        }
        setShowSplashScreen(false);
      } catch (error) {
        setShowSplashScreen(false);
        navigate("error/500");
      }
    };
    requestUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var newConfig = { ...config };
    if (!currentUser) {
      newConfig.isPublic = true;
    } else {
      newConfig.isPublic = false;
    }
    setLayout(newConfig);
  }, [currentUser]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
